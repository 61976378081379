@import 'mixins';

$itemHeight: 32px;
$itemHeightWithButton: 50px;
$borderWidth: 1px;

.custom-autocomplete.mat-mdc-autocomplete-panel {
  --mat-option-selected-state-label-text-color: theme('colors.primary.700');

  &.mdc-menu-surface {
    @apply py-1;
    box-shadow: none;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: var(--gray-300);

    &.custom-autocomplete-withgroups {
      &.mdc-menu-surface {
        @apply py-0;
        border-width: 1px;
      }
    }
  }

  &.custom-autocomplete--bottom.mdc-menu-surface {
    @apply pb-0 pt-1;
    overflow-y: hidden;
  }

  .mat-mdc-optgroup-label {
    @apply text-typography-primary bg-background-tertiary px-2;
    @include title-level;
    min-height: $itemHeight;
    position: sticky;
    top: 0;
    z-index: 1;

    .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
      max-width: 100%;
      width: 100%;
    }

    dojo-icon {
      align-items: center;
    }

    img {
      width: 16px;
    }
  }

  .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
    @apply pl-6;
  }

  .mat-mdc-option {
    @apply text-typography-primary text-body-m px-2;
    min-height: $itemHeight;

    .dark & {
      @apply text-typography-primary;
    }

    &:hover:not(.mdc-list-item--disabled),
    &:focus:not(.mdc-list-item--disabled),
    &.mat-mdc-option-active {
      @apply bg-gray-200;

      .dark & {
        @apply bg-gray-200;
      }
    }

    &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
      background: transparent;

      .dark & {
        background: transparent;
      }
    }

    .mdc-list-item__primary-text {
      --mdc-theme-primary: theme('colors.typography.primary');
      gap: theme('spacing.1');
      display: flex;
      align-items: center;
      max-width: 100%;
      width: 100%;

      img {
        width: 16px;
      }
    }
  }
}

.custom-autocomplete-info {
  @apply text-typography-disabled bg-gray-200 p-2;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--gray-300);
}

.custom-autocomplete-scroll-part {
  overflow-y: auto;
  height: calc(100% - #{$itemHeight} - #{$borderWidth});

  &.with-button {
    height: calc(100% - #{$itemHeightWithButton} - #{$borderWidth});
  }
}

.custom-autocomplete-scroll-part-without-footer {
  overflow-y: auto;
  height: 100%;
}
