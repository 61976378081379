@import 'mixins';

$close-icon-size: 16px;

.settings-dialog {
  @apply rounded-medium;
  height: 100%;
  border: 1px solid var(--gray-200);
}

.settings-dialog-sidenav {
  min-width: 180px;
  max-width: 200px;

  @media screen and (min-width: 960px) {
    max-width: 240px;
  }
}

.settings-dialog-header {
  @apply medium:pt-10 medium:pb-3 medium:mx-10 mx-5 mb-0 pb-2 pt-5;
  border-bottom: 1px solid var(--gray-200);

  &.has-no-border {
    @apply mb-0;
    border: unset;

    + .settings-dialog-content-inner {
      @apply pt-0;
    }
  }

  &.small-offset {
    @apply pb-6;

    + .settings-dialog-content-inner {
      @apply pt-6;
    }
  }
}

.settings-dialog-content.mat-drawer-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.settings-dialog-content-inner {
  @apply medium:p-10 medium:pt-8 p-5 pt-4;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  height: 100%;

  > * {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.settings-dialog-container {
  max-width: 380px;
  display: flex;
  flex-direction: column;
}

.settings-dialog-title-main {
  @apply text-typography-primary;
  @include title-level(text-title-l);
}

.settings-dialog-description-main {
  @apply text-body-s text-typography-secondary mb-0 mt-2;
}

.settings-dialog-title {
  @apply text-typography-primary mb-4;
  @include title-level(text-title-m);
}

.settings-dialog-paragraph,
.settings-item-paragraph {
  @apply text-body-s text-typography-secondary mb-3;
}

.settings-section-title {
  @apply mb-4;
  @include title-level(text-title-s);
}

.details-avatar {
  @apply mb-8 gap-4;
  display: flex;
  align-items: flex-end;
}

.details-avatar-image {
  position: relative;
  cursor: pointer;

  &:hover {
    .remove-image-button {
      display: block;
    }
  }

  &[disabled] {
    opacity: 0.64;
  }
}

.pending-image-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-image-button {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.settings-dialog-item {
  &:last-child {
    @apply mb-4;
  }

  + .settings-dialog-item {
    @apply medium:mt-8 mt-4;

    &.item-large-space {
      @apply medium:mt-10 mt-5;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .custom-mat-form-wrapper + .custom-mat-form-wrapper {
    @apply mt-4;
  }
}

.settings-dialog-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.settings-dialog-footer {
  @apply p-4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--gray-200);
}

.settings-row-buttons {
  @apply mt-4 gap-2;
  display: flex;
}

.settings-list-view .search-outside-wrapper {
  justify-content: space-between;
  width: 100%;

  .additional-buttons:empty {
    display: none;
  }
}

.settings-close-btn {
  &.mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
    @apply right-4 top-4;
    position: absolute;
  }
}
