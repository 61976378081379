$small-badge-size: 14px;
$medium-badge-size: 16px;

@mixin badge-size($size) {
  line-height: $size;
  width: $size;
  height: $size;
}

.custom-mat-badge {
  &.mat-badge-overlap.mat-badge-after {
    .mat-badge-content {
      @apply -right-1 -top-0.5;
      --mat-badge-text-weight: 500;
    }

    &.mat-badge-small {
      .mat-badge-content {
        @include badge-size($small-badge-size);
        --mat-badge-small-size-text-size: 7px;
      }
    }

    &.mat-badge-medium {
      .mat-badge-content {
        @include badge-size($medium-badge-size);
        --mat-badge-text-size: 8px;
      }
    }

    &.custom-mat-badge-center-end {
      .mat-badge-content {
        @apply right-2;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
