.custom-mat-stepper {
  --mat-stepper-header-selected-state-icon-background-color: var(--primary-700);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--white);
  --mat-stepper-header-done-state-icon-background-color: var(--gray-200);
  --mat-stepper-header-done-state-icon-foreground-color: var(--typography-secondary);
  --mat-stepper-header-edit-state-icon-background-color: var(--primary-700);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--white);
  --mat-stepper-header-label-text-color: var(--typography-secondary);
  --mat-stepper-header-label-text-size: theme('fontSize.title-s.0');
  --mat-stepper-header-label-text-weight: 600;
  --mat-stepper-header-icon-background-color: var(--gray-200);
  --mat-stepper-header-icon-foreground-color: var(--typography-secondary);
  --mat-stepper-header-selected-state-label-text-weight: 600;
  --mat-stepper-header-selected-state-label-text-color: var(--typography-primary);
  --mat-stepper-line-color: var(--gray-300);
  --mat-stepper-container-color: var(--background-secondary);
  --mat-stepper-header-hover-state-layer-color: transparent;
  --mat-stepper-header-focus-state-layer-color: transparent;
  --mat-stepper-header-height: 50px;
  --mat-stepper-header-selected-state-label-text-size: theme('fontSize.title-s.0');
  line-height: theme('fontSize.title-s.1');

  .dark & {
    --mat-stepper-header-selected-state-icon-background-color: var(--primary-400);
    --mat-stepper-header-selected-state-icon-foreground-color: var(--typography-primary);
    --mat-stepper-header-done-state-icon-foreground-color: var(--typography-secondary);
    --mat-stepper-header-edit-state-icon-background-color: var(--primary-400);
    --mat-stepper-header-label-text-weight: 500;
    --mat-stepper-header-selected-state-label-text-weight: 500;
  }

  .mat-step-icon {
    @apply text-body-s;
    width: 18px;
    height: 18px;
  }

  .mat-vertical-content-container {
    @apply ml-2;
  }

  .mat-vertical-stepper-header {
    @apply px-0 py-4;
    height: auto;

    .mat-step-icon {
      @apply mr-2;
    }
  }

  .mat-step-label-active:not(.mat-step-label-selected) {
    color: var(--mat-stepper-header-label-text-color);
  }

  .mat-vertical-content {
    @apply px-4 pb-4;
  }

  .mat-stepper-vertical-line::before {
    top: calc(8px - calc((var(--mat-stepper-header-height) - 18px) / 2));
    bottom: calc(8px - calc((var(--mat-stepper-header-height) - 18px) / 2));
  }
}
