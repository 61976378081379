$remove-icon-size: 12px;

.mat-mdc-standard-chip.mat-mdc-chip {
  --mdc-chip-elevated-container-color: var(--gray-200);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--gray-1200);
  --mdc-chip-elevated-disabled-container-color: var(--gray-200);
  --mdc-chip-disabled-label-text-color: var(--typography-disabled);
  --mdc-chip-label-text-weight: theme('fontWeight.normal');

  .dark & {
    --mdc-chip-label-text-weight: theme('fontWeight.normal-dark');
  }
}

.custom-chips-wrapper {
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    @apply py-1;
    display: flex;
    align-items: center;
    min-height: 32px;

    .mat-mdc-chip-set {
      width: 100%;
    }
  }

  .custom-chips.mat-mdc-chip {
    @apply rounded-standard m-0 p-1;
    position: relative;
    --mdc-chip-container-height: 24px;
    --mdc-chip-label-text-color: var(--typography-primary);

    .mdc-evolution-chip__action--primary {
      @apply m-0 p-0;
      display: flex;
      justify-content: flex-start;

      &::before {
        border: none;
      }

      .chip-avatar,
      .chip-avatar-users {
        max-width: 16px;
        max-height: 16px;
        width: 100%;
        height: 100%;
      }

      .chip-label-text {
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mat-mdc-chip-action-label {
        @apply gap-1;
        display: flex;
        align-items: center;
      }
    }

    .custom-chips-remove {
      @apply right-1 p-0;
      display: none;
      max-width: $remove-icon-size;
      max-height: $remove-icon-size;
      position: absolute;
      font-size: 14px;
    }

    &:hover {
      cursor: default;

      .mat-mdc-chip-action-label {
        width: calc(100% - #{$remove-icon-size});
      }

      .custom-chips-remove {
        display: flex;
      }
    }

    .mat-mdc-chip-focus-overlay {
      background: transparent;
    }

    .mat-ripple {
      display: none;
    }

    .mat-mdc-chip-remove {
      opacity: 1;
    }
  }

  .mat-mdc-chip-input {
    @apply text-body-m;
  }

  .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    @apply ml-0 gap-2;
  }

  .mat-mdc-chip-input {
    @apply ml-0;
  }
}
