@import 'mixins';

//TODO CSS Team move to reusable
.message-wrapper {
  background-color: theme('colors.gray.100');
  padding: theme('spacing.8') theme('spacing.6');
  border-radius: theme('borderRadius.medium');
  border: 1px solid var(--gray-200);
  text-align: center;
  max-width: 350px;
  width: 100%;
  margin: auto;

  .message-title:not(:empty) {
    @include title-level(text-title-m);
    color: theme('colors.typography.primary');
    margin-bottom: theme('spacing.0');

    + .message-description {
      margin-top: theme('spacing.2');
    }

    + .message-btn {
      margin-top: theme('spacing.6');
    }
  }

  .message-title:empty {
    display: none;
  }

  .message-description {
    font-size: theme('fontSize.body-m.0');
    line-height: theme('fontSize.body-m.1');
    color: theme('colors.typography.secondary');
    margin-bottom: theme('spacing.0');

    + .message-btn {
      margin-top: theme('spacing.8');
    }
  }

  .message-icon {
    margin-bottom: theme('spacing.5');
    justify-content: center;
    align-items: center;
  }

  .message-btn {
    ::ng-deep {
      .mdc-button__label {
        z-index: auto;
      }
    }
  }
}
