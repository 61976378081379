// Do not import this anywhere other than the global examdojo-styles.scss
@import 'variables';

:root {
  --neutral-0: #ffffff;
  --neutral-50: #f6f6f6;
  --neutral-100: #e7e7e7;
  --neutral-200: #d1d1d1;
  --neutral-300: #b0b0b0;
  --neutral-400: #888888;
  --neutral-500: #6d6d6d;
  --neutral-600: #5d5d5d;
  --neutral-700: #4f4f4f;
  --neutral-800: #454545;
  --neutral-900: #3d3d3d;
  --neutral-950: #262626;
  --neutral-1000: #000000;

  --amber-50: #fff8eb;
  --amber-100: #fff5c6;
  --amber-200: #ffe888;
  --amber-300: #ffd74a;
  --amber-400: #ffc424;
  --amber-500: #f9a107;
  --amber-600: #dd7902;
  --amber-700: #b75506;
  --amber-800: #94400c;
  --amber-900: #7a350d;
  --amber-950: #461b02;

  --teal-50: #f0fdfb;
  --teal-100: #cdfaf4;
  --teal-200: #9af5e8;
  --teal-300: #60e8db;
  --teal-400: #2dccd2;
  --teal-500: #16b6ad;
  --teal-600: #0f928d;
  --teal-700: #117473;
  --teal-800: #125d56;
  --teal-900: #144d4c;
  --teal-950: #052d2e;

  --green-50: #f1fdf0;
  --green-100: #defbdd;
  --green-200: #bdf5bd;
  --green-300: #89ec89;
  --green-400: #6ec06c;
  --green-500: #27c027;
  --green-600: #1a9f1a;
  --green-700: #187d19;
  --green-800: #186316;
  --green-900: #165117;
  --green-950: #062d08;

  --sky-50: #f0f8ff;
  --sky-100: #e0f0fe;
  --sky-200: #bae1fd;
  --sky-300: #7ccafd;
  --sky-400: #57bcfa;
  --sky-500: #0d95ea;
  --sky-600: #0175c8;
  --sky-700: #025da2;
  --sky-800: #054e86;
  --sky-900: #0b436f;
  --sky-950: #082a49;

  --indigo-50: #edf2ff;
  --indigo-100: #dee8ff;
  --indigo-200: #c4d3ff;
  --indigo-300: #a0b6ff;
  --indigo-400: #7589ff;
  --indigo-500: #5b66f9;
  --indigo-600: #3e3dee;
  --indigo-700: #332fd3;
  --indigo-800: #2a29aa;
  --indigo-900: #292b86;
  --indigo-950: #19184e;

  --pink-50: #fdf2f6;
  --pink-100: #fce7ef;
  --pink-200: #fad0e0;
  --pink-300: #f7aac5;
  --pink-400: #f7aac5;
  --pink-500: #e94b7a;
  --pink-600: #d72b56;
  --pink-700: #bb1b3f;
  --pink-800: #9a1a35;
  --pink-900: #811a2f;
  --pink-950: #4f0816;

  --red-50: #fff1f1;
  --red-100: #ffe3e4;
  --red-200: #ffcccc;
  --red-300: #ffa2a9;
  --red-400: #fe6e7b;
  --red-500: #fb3b51;
  --red-600: #e5193b;
  --red-700: #c20e30;
  --red-800: #a20f30;
  --red-900: #88102f;
  --red-950: #4e0314;

  --orange-50: #fff8ed;
  --orange-100: #ffefd5;
  --orange-200: #ffdaa9;
  --orange-300: #feb573;
  --orange-400: #fd9e44;
  --orange-500: #fb7b14;
  --orange-600: #ec5f0a;
  --orange-700: #c4470a;
  --orange-800: #9b3811;
  --orange-900: #7d3011;
  --orange-950: #441606;

  --purple-50: #f9f5ff;
  --purple-100: #f2e9fe;
  --purple-200: #e6d6fe;
  --purple-300: #d3b6fc;
  --purple-400: #b582f8;
  --purple-500: #9e5af2;
  --purple-600: #8739e4;
  --purple-700: #7327c9;
  --purple-800: #6225a4;
  --purple-900: #511f84;
  --purple-950: #350a61;

  --yellow-50: #fffbeb;
  --yellow-100: #fff3c6;
  --yellow-200: #ffe488;
  --yellow-300: #ffd14a;
  --yellow-400: #ffba1a;
  --yellow-500: #f99a07;
  --yellow-600: #dd7202;
  --yellow-700: #b74f06;
  --yellow-800: #943c0c;
  --yellow-900: #7a320d;
  --yellow-950: #461802;

  --blue-50: #eef9ff;
  --blue-100: #dcf2ff;
  --blue-200: #b2e8ff;
  --blue-300: #6dd7ff;
  --blue-400: #20c2ff;
  --blue-500: #00abff;
  --blue-600: #0088df;
  --blue-700: #006cb4;
  --blue-800: #005c95;
  --blue-900: #004b7a;
  --blue-950: #001524;

  --violet-50: #faf5fa;
  --violet-100: #f7ecf6;
  --violet-200: #f0daee;
  --violet-300: #e4bde0;
  --violet-400: #daa4d3;
  --violet-500: #c272b6;
  --violet-600: #ad559b;
  --violet-700: #944280;
  --violet-800: #7b396b;
  --violet-900: #68335a;
  --violet-950: #3e1935;

  --primary-100: #fbf8ff;
  --primary-200: #edebfe;
  --primary-300: #d2cffd;
  --primary-400: #b4b1fd;
  --primary-500: #9a92fe;
  --primary-600: #8672ff;
  --primary-700: #7f4eff;
  --primary-800: #742af5;
  --primary-900: #6120d1;
  --primary-1000: #4d20a7;
  --primary-1100: #361d73;
  --primary-1200: #311b68;

  --gray-100: #fbf9fb;
  --gray-200: #edecef;
  --gray-300: #d4d3d8;
  --gray-400: #b7b8bf;
  --gray-500: #9d9ea7;
  --gray-600: #858791;
  --gray-700: #70737e;
  --gray-800: #5e606b;
  --gray-900: #4d505b;
  --gray-1000: #3f414c;
  --gray-1100: #2d2f37;
  --gray-1200: #292b33;

  //Additional colors
  --white: #ffffff;
  --black: #17181c;
  --black-pure: #000;
  --purple: #f4f4fb;
  --text: 41, 43, 51;
  --text-red: 207, 62, 67; //red-700

  --primary: var(--neutral-800);
  --secondary: var(--neutral-600);
  --disabled: var(--neutral-300);

  //RGB values
  --white-rgb: 255, 255, 255;
  --gray-100-rgb: 251, 249, 251;

  //Header colors
  --menu-background: var(--primary-1200);
  --main-btn-header-background: var(--primary-1000);
  --main-btn-secondary-header-background: var(--primary-1100);
  --header-toggle-checked: var(--primary-700);
  --secondary-btn-header-background: var(--primary-1000);

  //Backgrounds
  --background-primary: var(--white);
  --background-secondary: var(--white);
  --background-tertiary: var(--purple);
  --background-quaternary: var(--purple);
  --background-quinary: var(--white);

  //Canvas
  --canvas-grid-color: var(--primary-200);

  //Fonts
  //UX3
  --typography-primary: rgba(var(--text), #{$opacity-level-1});
  --typography-secondary: rgba(var(--text), #{$opacity-level-2});
  --typography-tertiary: var(--black);
  --typography-quaternary: rgba(var(--white-rgb), #{$opacity-level-1});
  --typography-disabled: rgba(var(--text), #{$opacity-level-3});
  --typography-danger-primary: rgb(var(--text-red));
  --typography-danger-disabled: rgba(var(--text-red), #{$opacity-level-3});

  //Buttons
  --button-bg-color: var(--primary-700);
  --input-border-color: var(--primary-700);

  //Focus
  --focus-color: var(--primary-400);
}
