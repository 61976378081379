@use 'ag-grid-community/styles' as ag;
@import 'variables';
@import 'mixins';

$shortcut-key-size: 16px;
$light-background-text: rgba(var(--text), #{$opacity-level-1});

@include ag.grid-styles(
  (
    theme: basic-table,
    extend-theme: material,
  )
);

@include ag.grid-styles(
  (
    theme: unthemed,
    extend-theme: material,
  )
);

@mixin ag-grid-colors {
  --ag-material-primary-color: var(--primary-700);
  --ag-material-accent-color: var(--orange-700);
  --ag-data-color: var(--typography-primary);
  --ag-background-color: var(--background-secondary);
  --ag-tooltip-background-color: var(--gray-100);
  --ag-checkbox-checked-color: var(--primary-700);
  --ag-checkbox-unchecked-color: var(--gray-400);
  --ag-checkbox-indeterminate-color: var(--gray-400);
  --ag-row-hover-color: var(--gray-200);
  --ag-header-cell-hover-background-color: var(--gray-200);
  --ag-secondary-foreground-color: var(--typography-primary);
  --ag-subheader-background-color: var(--gray-100);
  --ag-foreground-color: var(--gray-1100);
  --ag-control-panel-background-color: transparent;
  --ag-border-color: var(--gray-200);
  --ag-selected-row-background-color: var(--gray-200);
  --ag-range-selection-highlight-color: var(--primary-400);
  --ag-chip-background-color: var(--gray-200);
  --ag-disabled-foreground-color: rgba(var(--gray-1100), 0.38);
  --ag-modal-overlay-background-color: rgba(var(--background-secondary), 0.66);
  --ag-toggle-button-off-background-color: var(--gray-300);
  --ag-toggle-button-off-border-color: var(--gray-300);
  --ag-toggle-button-switch-background-color: var(--gray-500);
  --ag-toggle-button-on-background-color: var(--primary-700);
  --ag-toggle-button-on-border-color: var(--primary-700);

  --ag-header-background-color: var(--background-color-accent);
  --ag-subheader-toolbar-background-color: var(--background-color-accent);
  --ag-odd-row-background-color: var(--background-color-secondary);

  .dark & {
    --ag-toggle-button-on-background-color: var(--primary-400);
    --ag-toggle-button-on-border-color: var(--primary-400);
  }
}

@mixin ag-grid-context-menu {
  --ag-grid-size: theme('spacing[1]');
  --ag-background-color: var(--gray-100);
  --ag-card-radius: theme('borderRadius.DEFAULT');
  --ag-row-hover-color: var(--gray-200);
  --ag-border-color: theme('colors.gray.200');
  --ag-borders-critical: 1px solid;
  --ag-foreground-color: theme('colors.typography.disabled');
  --ag-icon-size: #{$shortcut-key-size};
  --ag-popup-shadow: 1px 4px 14px rgba(0, 0, 0, 0.3);
  --ag-font-size: theme('fontSize.body-m.0');
  --ag-selected-tab-underline-width: 1px;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-700);
  @include border;

  .dark & {
    --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-400);
  }

  .ag-menu-list {
    @apply p-0;
    border-spacing: 0 theme('spacing.1');
  }

  .ag-menu-option {
    > span:first-child {
      border-top-left-radius: theme('borderRadius.sm');
      border-bottom-left-radius: theme('borderRadius.sm');
    }

    > span:last-child {
      border-top-right-radius: theme('borderRadius.sm');
      border-bottom-right-radius: theme('borderRadius.sm');
    }

    &:hover:not(.ag-menu-option-disabled) {
      cursor: pointer;
    }

    .ag-menu-option-text {
      @apply pr-8;
    }

    .ag-menu-option-shortcut {
      @apply gap-0.5;
      display: flex;
    }

    &.dangerous-action:not(.ag-menu-option-disabled) {
      @apply text-typography-danger-primary;
    }

    .shortcut-key {
      @apply text-body-xs rounded-sm bg-gray-200;
      width: $shortcut-key-size;
      height: $shortcut-key-size;
      display: flex;
      align-items: center;
      justify-content: center;

      .cmd-key {
        @apply text-body-m;
      }
    }

    .ag-menu-option-popup-pointer {
      .ag-icon-small-right {
        @apply text-body-s;

        &::before {
          display: block;
          content: '\f078';
          font: var(--fa-font-light);
          transform: rotate(-90deg);

          .mdc-text-field--disabled & {
            @apply text-typography-disabled;
          }
        }
      }
    }
  }

  .ag-menu-separator {
    height: 1px;
  }

  .ag-menu-option-icon {
    padding-left: theme('spacing.3');

    i {
      width: 15px;
    }
  }

  .ag-tab-guard:empty {
    display: none;
  }

  .ag-menu-list {
    border-collapse: collapse;
  }

  .ag-menu-option-popup-pointer,
  .ag-compact-menu-option-popup-pointer {
    padding-right: theme('spacing.3');
  }

  .ag-tab {
    height: calc(var(--ag-grid-size) * 6);
  }

  input[class^='ag-'] {
    &:not([type]),
    &[type='text'],
    &[type='number'],
    &[type='tel'],
    &[type='date'],
    &[type='datetime-local'],
    textarea[class^='ag-'] {
      height: calc(var(--ag-grid-size) * 6);
    }
  }
}

@mixin ag-grid-typography($commonFontSize, $headerFontSize: $commonFontSize) {
  @apply font-primary dark:font-normal-dark #{$commonFontSize} text-typography-primary font-normal;

  .ag-header-row {
    @apply dark:font-normal-dark #{$headerFontSize} font-normal;
  }

  .ag-cell {
    -webkit-font-smoothing: antialiased;
  }
}

@mixin ag-echarts {
  .ag-dialog {
    --ag-echart-toolbar-icon-size: 12px;

    --ag-background-color: var(--background-secondary);
    border: 1px solid var(--gray-200);
    border-radius: theme('borderRadius.DEFAULT');

    .ag-chart-tool-panel-button-enable .ag-chart-menu {
      background-color: transparent;
      right: theme('spacing.3');

      &-icon {
        color: var(--gray-700);
        font-size: 18px;
        line-height: 18px;
        width: 18px;
        height: 18px;
      }
    }

    .ag-charts-format-top-level-group-container {
      padding: 0 theme('spacing.2');
      margin-left: 0;
    }

    .ag-slider,
    .ag-angle-select {
      .ag-number-field-input {
        border: 1px solid var(--gray-200);
        border-radius: theme('borderRadius.md');
        padding-left: theme('spacing.2');
        height: 30px;

        &:focus {
          border-color: var(--focus-color);
          border-bottom-width: 1px;
        }
      }
    }

    .ag-angle-select-wrapper {
      align-items: center;
    }

    .ag-chart-menu-close {
      &,
      .ag-icon {
        border-top-left-radius: theme('borderRadius.DEFAULT');
        border-bottom-left-radius: theme('borderRadius.DEFAULT');
      }

      &::before {
        top: -20px;
        bottom: -20px;
      }
    }

    .ag-chart-tabbed-menu-header {
      border-bottom: 1px solid var(--gray-200);
    }

    .ag-tab {
      height: auto;
      padding: theme('spacing.2');
    }

    .ag-charts-format-sub-level-group-item:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin ag-grid-common-styles($header-size: 3) {
  // multiply 8 according default material theme
  @include ag-grid-colors;
  @include ag-echarts;

  --ag-header-height: calc(var(--ag-grid-size) * #{$header-size});
  --ag-cell-horizontal-padding: theme('spacing.3');

  --ag-checkbox-border-radius: theme('borderRadius.DEFAULT');
  --ag-toggle-button-width: #{$switch-toggle-track-width};
  --ag-toggle-button-height: #{$switch-toggle-track-height};
  --ag-toggle-button-border-width: 0;

  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size));
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size));

  .ag-checked {
    --ag-toggle-button-switch-background-color: var(--white);
  }

  &.auto-height-grid .ag-layout-auto-height {
    .ag-center-cols-container,
    .ag-center-cols-clipper {
      min-height: 100px;
    }
  }

  .ag-tooltip {
    color: theme('colors.typography.primary');
    padding: theme('spacing.1');
    box-shadow: none;
    font-size: theme('fontSize.body-s.0');
    line-height: theme('fontSize.body-s.1');
    border: 1px solid theme('colors.gray.200');
    border-radius: theme('borderRadius.DEFAULT');
    min-width: 40px;
    max-width: 200px;
  }

  .ag-cell {
    &.table-center-align {
      display: flex;
      align-items: center;
    }
  }

  .ag-overlay-no-rows-wrapper {
    @apply text-typography-secondary text-body-m;

    &.ag-layout-auto-height {
      padding-top: 0;
    }
  }

  .ag-group-contracted,
  .ag-group-expanded {
    --ag-cell-horizontal-padding: theme('spacing.4');
  }

  .ag-group-contracted .ag-icon,
  .ag-group-expanded .ag-icon {
    @apply text-typography-primary;
    --ag-icon-size: 14px;
  }

  .ag-group-title-bar {
    gap: theme('spacing.1');

    &-icon {
      margin-right: 0;
    }

    .ag-icon {
      --ag-icon-size: 12px;
    }
  }

  .ag-radio-button-input-wrapper,
  .ag-checkbox-input-wrapper {
    --ag-icon-size: 16px;
  }

  .ag-checkbox-input-wrapper {
    &::after {
      @apply rounded;
      border: 1px solid var(--gray-400);
      background: none;
      width: var(--ag-icon-size);
      height: var(--ag-icon-size);
    }

    &:not(.ag-checked):not(.ag-indeterminate)::after {
      content: '';
    }

    //replace default ag grid check
    &.ag-checked::after {
      @apply bg-primary-700 rounded border-none font-medium text-white;
      text-align: center;
      content: '\f00c';
      font: var(--fa-font-regular);
      font-size: calc(var(--ag-icon-size) - 4px);
      align-items: center;
      display: flex;
      justify-content: center;

      .dark & {
        @apply bg-primary-400;
      }
    }

    &.ag-indeterminate::after {
      @apply border-none;
    }
  }

  .ag-icon-tree-open,
  .ag-icon-tree-closed {
    min-width: var(--ag-icon-size);

    &::before {
      font: var(--fa-font-regular);
    }
  }

  .ag-icon-tree-open::before {
    content: '\f078';
  }

  .ag-icon-tree-closed::before {
    content: '\f054';
  }

  .ag-column-select {
    border-bottom: 0;
  }

  .ag-menu {
    @include ag-grid-context-menu();
  }

  .ag-sidebar {
    input[class^='ag-'][type='text'] {
      padding-bottom: 0;
    }
  }

  .ag-filter {
    .ag-filter-apply-panel {
      display: flex;
      align-items: center;
      gap: theme('spacing.2');

      .ag-standard-button {
        text-transform: capitalize;
        padding: theme('spacing.1') theme('spacing.3');
        border: 1px solid;
        font-weight: theme('fontWeight.medium');
        font-size: theme('fontSize.title-s.0');
        line-height: theme('fontSize.title-s.1');
        border-radius: theme('borderRadius.DEFAULT');
        color: theme('colors.typography.primary');
        margin: 0;

        &[ref='resetFilterButton'] {
          border-color: theme('colors.gray.300');
          background-color: theme('colors.gray.100');

          &:hover {
            background-color: theme('colors.gray.200');
          }
        }

        &[ref='applyFilterButton'] {
          border-color: theme('colors.primary.400');
          background-color: theme('colors.primary.400');

          &:hover {
            background-color: theme('colors.primary.500');
          }
        }

        .dark & {
          font-weight: theme('fontWeight.medium-dark');
        }
      }
    }

    .ag-simple-filter-body-wrapper {
      padding-top: theme('spacing.px');
      --ag-widget-vertical-spacing: theme('spacing.2');

      .ag-input-field-input {
        height: 30px;
      }
    }
  }

  .ag-icon {
    --ag-icon-size: 14px;
  }

  .ag-filter-toolpanel-group-level-0-header {
    height: 32px;
    margin-bottom: 1px;
  }

  .ag-filter-toolpanel-search {
    height: 32px;
  }

  .ag-select-list {
    @apply bg-background-secondary;
  }

  .ag-wrapper .ag-input-field-input.ag-number-field-input {
    padding-bottom: 0;
  }

  .ag-wrapper .ag-input-field-input.ag-text-field-input {
    border-bottom: 0;
    padding-bottom: 0;
    --ag-foreground-color: var(--typography-primary);

    &::placeholder {
      @apply text-typography-disabled;
    }

    &::-webkit-calendar-picker-indicator {
      .dark & {
        filter: invert(80%);
      }
    }

    &:focus {
      border-bottom: 0;
    }
  }

  .ag-cell-inline-editing {
    @apply bg-gray-200 py-0 pl-3 pr-8;
    border: none;
    height: 100%;
    box-shadow: none;
    display: flex;

    .ag-input-wrapper {
      align-items: flex-start;
    }

    .ag-cell-edit-wrapper {
      position: relative;

      &::after {
        @apply text-primary-600;
        position: absolute;
        right: -24px;
        top: 14px;
        width: 14px;
        height: 14px;
        content: '\f040';
        font: var(--fa-font-regular);
      }
    }

    .ag-input-field-input.ag-text-field-input {
      @apply pb-0;
      border-bottom: 0;

      &:focus {
        border-bottom: 0;
      }
    }
  }

  .ag-popup-editor {
    @apply rounded py-0;
    box-shadow: none;
    max-width: 300px;
  }

  .ag-header-cell-text:empty ~ .ag-sort-indicator-container {
    .ag-sort-ascending-icon,
    .ag-sort-descending-icon {
      @apply pl-0;
    }
  }

  .ag-right-aligned-header {
    .ag-cell-label-container {
      @apply flex-row-reverse;

      .custom-header-cell-wrapper,
      &:not(:has(.custom-header-cell-wrapper)) .ag-header-cell-text {
        @apply mr-2;
      }
    }
  }

  .ag-checkbox {
    .ag-checkbox-label {
      @apply ml-2;
    }
  }

  .ag-set-filter {
    --ag-list-item-height: auto;

    .ag-mini-filter {
      --ag-widget-container-vertical-padding: theme('spacing.2');
    }
  }

  &.hide-filter-menu .ag-header-cell-menu-button {
    display: none;
  }

  .ag-column-panel,
  .ag-column-select-column {
    --ag-widget-horizontal-spacing: theme('spacing.2');
  }

  .ag-select-agg-func-popup {
    @apply rounded;
    --ag-background-color: var(--background-secondary);
    border: 1px solid var(--gray-200);
  }

  .ag-select-agg-func-virtual-list-item {
    @apply px-3;
    cursor: pointer;
  }

  .ag-column-select {
    .ag-column-select-header .ag-input-field-input {
      @apply pb-0;
    }

    .ag-column-select-list {
      .ag-column-select-column {
        cursor: pointer;
      }

      .ag-column-select-column-label {
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
      }
    }
  }

  .ag-column-drop-vertical-empty-message {
    @apply text-typography-secondary font-normal;
  }

  .ag-column-drop-vertical {
    border-top: 1px solid var(--ag-border-color);
    border-bottom: none;

    .ag-column-drop-vertical-title-bar {
      @apply mb-2;
    }

    .ag-column-drop-vertical-cell {
      @apply border-gray-300;
      cursor: pointer;

      &:first-child {
        @apply mt-0;
      }
    }
  }

  &.ag-dnd-ghost {
    @apply pb-0;
  }

  .ag-tool-panel-wrapper {
    .ag-pivot-mode-select,
    .ag-toggle-button-input,
    .ag-checkbox-input {
      cursor: pointer;
    }
  }

  .ag-toggle-button-input-wrapper {
    border-radius: $switch-toggle-track-border-radius;

    &:not(.ag-checked) {
      &:hover {
        --ag-toggle-button-off-background-color: var(--gray-400);
        --ag-toggle-button-switch-background-color: var(--gray-600);
      }

      &::before {
        left: $switch-toggle-handle-offset;
      }
    }

    &.ag-checked::before {
      left: calc(100% - var(--ag-toggle-button-height) - #{$switch-toggle-handle-offset});
    }

    &::before {
      --ag-toggle-button-height: #{$switch-toggle-handle-size};
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .ag-overlay-no-rows-wrapper {
    @apply text-typography-secondary;
  }
}

.ag-theme-basic-table {
  @include ag-grid-typography(text-body-m, text-body-s);
  @include ag-grid-common-styles;

  --ag-row-height: calc(var(--ag-grid-size) * 5);
  --ag-header-background-color: var(--gray-200);

  //Using in bottom drawer Build Alerts and Scheduler tabs
  &.extended-height {
    @include ag-grid-typography(text-body-s);
    --ag-header-height: calc(var(--ag-grid-size) * 4);
    --ag-row-height: calc(var(--ag-grid-size) * 6);
    --ag-header-background-color: transparent;
  }

  &.transparent-background {
    --ag-background-color: transparent;
    --ag-selected-row-background-color: transparent;
  }

  &.no-border {
    .ag-header,
    .ag-row {
      border-bottom: none;
    }
  }

  &.table-cols-borders {
    --ag-header-background-color: transparent;
    --ag-background-color: transparent;
    --ag-even-row-background-color: var(--gray-100);
    --ag-cell-horizontal-padding: theme('spacing.3');
    --ag-odd-row-background-color: var(--background-primary);
    --ag-row-hover-color: initial;

    .ag-row-even {
      background: var(--ag-even-row-background-color); //need?
    }

    .ag-header,
    .ag-row {
      border-bottom: none;
    }

    .ag-header-container,
    .ag-row:last-child {
      border-bottom: 1px solid var(--gray-200);
    }

    .ag-header-cell,
    .ag-cell:not(.ag-cell-focus):not(.ag-cell-range-selected) {
      border-right: 1px solid var(--gray-200);
    }

    .ag-header-cell {
      @apply p-2;

      &:hover,
      &:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover {
        --ag-header-cell-hover-background-color: var(--gray-100);
      }
    }

    .ag-header:has(.min-height-transition) {
      transition: min-height 0.4s linear;
    }

    .ag-header:has(.height-transition),
    .ag-header-row {
      transition:
        height 0.4s linear,
        min-height 0.4s linear;
    }

    // NOTE: to overwrite inline style
    .ag-header-container,
    .ag-center-cols-container {
      min-width: 100% !important;
    }

    .ag-header-cell-comp-wrapper {
      align-items: flex-start;
    }
  }

  &.all-borders {
    --ag-background-color: unset;
    --ag-even-row-background-color: transparent;
    --ag-odd-row-background-color: transparent;
    --ag-header-background-color: transparent;
    --ag-header-cell-hover-background-color: transparent;
    --ag-row-hover-color: initial;
    --ag-row-height: calc(var(--ag-grid-size) * 4);
    --ag-header-height: calc(var(--ag-grid-size) * 4);
    padding-bottom: theme('spacing.6');

    .ag-header-container,
    .ag-row {
      border-bottom: 1px solid var(--gray-200);
    }

    .ag-header-cell,
    .ag-cell:not(.ag-cell-focus):not(.ag-cell-range-selected) {
      border-right: 1px solid var(--gray-200);
    }

    .ag-header-group-cell {
      border-top: 1px solid var(--gray-200);
      border-right: 1px solid var(--gray-200);
    }

    .ag-header-cell {
      @apply p-2;

      &:hover,
      &:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover {
        --ag-header-cell-hover-background-color: var(--gray-100);
      }
    }

    .ag-header:has(.min-height-transition) {
      transition: min-height 0.4s linear;
    }

    .ag-header:has(.height-transition),
    .ag-header-row {
      transition:
        height 0.4s linear,
        min-height 0.4s linear;
    }

    // NOTE: to overwrite inline style
    .ag-header-container,
    .ag-center-cols-container {
      min-width: 100% !important;
    }

    .force-pk-fixed-header .ag-header-cell-comp-wrapper {
      align-items: flex-start;
    }
  }

  .ag-header {
    border-bottom: none;
    border-top-left-radius: theme('borderRadius.DEFAULT');
    border-top-right-radius: theme('borderRadius.DEFAULT');
  }

  .ag-header-cell,
  .ag-icon {
    @apply text-typography-secondary;
  }
}

.ag-theme-unthemed {
  --ag-material-primary-color: unset;
  --ag-material-accent-color: unset;
  --ag-data-color: unset;
  --ag-background-color: unset;
  --ag-checkbox-unchecked-color: unset;
  --ag-row-hover-color: var(--blue-50);
  --ag-header-cell-hover-background-color: unset;
  --ag-secondary-foreground-color: unset;
  --ag-subheader-background-color: unset;
  --ag-foreground-color: unset;
  --ag-control-panel-background-color: unset;
  --ag-border-color: unset;
  --ag-selected-row-background-color: var(--blue-50);
  --ag-chip-background-color: unset;
  --ag-disabled-foreground-color: unset;
  --ag-modal-overlay-background-color: unset;
  --ag-header-background-color: unset;
  --ag-subheader-toolbar-background-color: unset;
  --ag-odd-row-background-color: unset;
  --ag-range-selection-border-color: unset;
  --ag-range-selection-border-style: none;
  --ag-value-change-value-highlight-background-color: var(--blue-50);
  --ag-font-family: theme('fontFamily.primary');
  --ag-font-size: theme('fontSize.body-m');
  --ag-card-shadow: unset;

  .dark & {
    --ag-selected-row-background-color: var(--gray-200);
  }

  .ag-menu {
    @include ag-grid-context-menu();
  }

  .ag-cell,
  .ag-row::before {
    @apply rounded;
  }

  .ag-row-dragging {
    opacity: 1;
  }

  .ag-cell {
    //-webkit-font-smoothing: antialiased;
  }

  .ag-cell.dragging-target {
    @apply bg-gray-300;
  }

  .dragging-target-area {
    @apply bg-gray-200;
  }

  .ag-row-focus:not(.ag-row-inline-editing) {
    &.ag-row-selected.ag-row-hover {
      --ag-data-color: #{$light-background-text};
    }

    &:focus-within {
      .ag-cell-focus {
        outline: 1px solid var(--primary-600);
        outline-offset: -1px;

        &:hover {
          --ag-data-color: #{$light-background-text};
        }
      }
    }
  }

  .ag-row-selected {
    &.node-row {
      --ag-data-color: #{$light-background-text};
    }
  }

  .node-row:not(.ag-row-selected) .active {
    @apply bg-blue-50 text-blue-700;
    //color: $light-background-text;

    &:hover {
      @apply bg-blue-50 text-blue-700;
    }
  }

  .dnd-item {
    @apply bg-primary-100;

    .dark & {
      @apply bg-primary-200;
    }
  }

  &.ag-dnd-ghost {
    cursor: default;

    .ag-dnd-ghost-label,
    .ag-dnd-ghost-icon {
      display: none;
    }
  }

  .ag-overlay-no-rows-wrapper {
    @apply text-typography-secondary;
  }
}

.ag-cell-wrapper {
  align-items: center !important;
}

.ag-sticky-top {
  @apply bg-background-secondary;
}
