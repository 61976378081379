@import 'mixins';

.custom-toggle-group {
  --mat-standard-button-toggle-selected-state-background-color: var(--gray-100);

  &.mat-button-toggle-group {
    @apply rounded-standard;
    @include border;
  }

  .mat-button-toggle-appearance-standard {
    @apply text-body-xs text-typography-secondary bg-gray-100;

    .mat-button-toggle-label-content {
      @apply p-0;
      line-height: theme('fontSize.body-xs.1');
    }

    &.mat-button-toggle + .mat-button-toggle {
      border-left: none;
    }
  }

  .mat-button-toggle {
    @apply py-1;

    &:first-child {
      @apply pl-1 pr-0.5;
    }

    &:last-child {
      @apply pl-0.5 pr-1;
    }

    &.mat-button-toggle-checked {
      .mat-button-toggle-button {
        @apply bg-primary-700 dark:bg-primary-400 dark:font-medium-dark text-typography-quaternary font-medium;

        .dark & {
          @apply text-typography-primary;
        }
      }
    }
  }

  .mat-button-toggle-button {
    @apply rounded-micro px-1 py-0.5;
    display: flex;
  }
}
