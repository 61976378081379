.mat-mdc-select-value {
  @apply text-typography-primary;

  .dark & {
    @apply text-typography-primary;
  }

  .mat-mdc-select-disabled & {
    @apply text-typography-disabled;

    .dark & {
      @apply text-typography-disabled;
    }
  }
}

.mat-mdc-select-placeholder {
  @apply text-typography-secondary;

  .dark & {
    @apply text-typography-secondary;
  }

  .mat-mdc-select-disabled & {
    @apply text-typography-disabled;

    .dark & {
      @apply text-typography-disabled;
    }
  }
}

mat-select.mat-mdc-select {
  --mat-select-trigger-text-weight: theme('fontWeight.normal');

  .dark & {
    --mat-expansion-header-text-weight: theme('fontWeight.normal-dark');
  }

  @apply text-body-m;
  display: flex;

  .mat-mdc-select-arrow {
    display: none;
  }

  .mat-mdc-select-arrow-wrapper {
    height: 16px;

    &::after {
      @apply text-gray-800;
      content: '\f078';
      font: var(--fa-font-regular);

      .mdc-text-field--disabled & {
        @apply text-typography-disabled;
      }
    }

    svg {
      display: none;
    }
  }

  &[aria-expanded='true'] {
    .mat-mdc-select-arrow-wrapper::after {
      transform: rotate(180deg);
    }
  }
}

.mat-mdc-form-field-type-mat-select {
  &:hover {
    .mdc-text-field {
      &--filled:not(.mdc-text-field--disabled) {
        @apply bg-gray-200;

        .dark & {
          @apply bg-gray-200;
        }
      }
    }
  }

  .mdc-text-field {
    cursor: pointer;

    &--filled:not(.mdc-text-field--disabled) {
      background: transparent;

      .dark & {
        background: transparent;
      }
    }
  }

  &.mat-focused {
    .mdc-text-field {
      @apply bg-gray-200;
      border-color: var(--focus-color);

      .dark & {
        @apply bg-gray-200;
        border-color: var(--focus-color);
      }
    }
  }
}

.custom-mat-select-panel {
  --mdc-theme-primary: theme('colors.typography.disabled');
  --mat-option-selected-state-label-text-color: theme('colors.primary.700');
  --mat-option-label-text-color: theme('colors.typography.primary');

  &.mdc-menu-surface {
    --mdc-shape-medium: theme('borderRadius.standard');
    box-shadow: none;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: var(--gray-300);

    &.mat-mdc-select-panel {
      @apply py-1;

      &.no-padding {
        @apply py-0;
      }
    }
  }

  .mat-mdc-optgroup {
    --mat-optgroup-label-text-weight: theme('fontWeight.normal');
    --mat-option-label-text-weight: theme('fontWeight.normal');

    .dark & {
      --mat-optgroup-label-text-weight: theme('fontWeight.normal-dark');
      --mat-option-label-text-weight: theme('fontWeight.normal-dark');
    }
  }

  .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
    @apply pl-2;
  }

  .mat-mdc-optgroup-label {
    @apply text-body-s bg-gray-200 py-1 pl-2;
    min-height: auto;
    position: sticky;
    top: 0;
    z-index: 1;

    .mdc-list-item__primary-text {
      font-size: theme('fontSize.body-s.0');
      line-height: theme('fontSize.body-s.1');
      color: theme('colors.typography.secondary');
    }
  }

  .mat-mdc-option {
    @apply px-2;
    min-height: 32px;

    &:hover:not(.mdc-list-item--disabled),
    &:focus:not(.mdc-list-item--disabled),
    &.mat-mdc-option-active {
      @apply bg-gray-200;

      .dark & {
        @apply bg-gray-200;
      }
    }

    &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
      background: transparent;

      .dark & {
        background: transparent;
      }
    }

    .mat-mdc-menu-item-text,
    .mdc-list-item__primary-text {
      @apply font-normal;
      --mdc-theme-primary: theme('colors.typography.primary');
      --mat-select-trigger-text-weight: theme('fontWeight.normal');
      width: 100%;
      display: flex;
      align-items: center;

      .dark & {
        @apply font-normal-dark;
        --mat-expansion-header-text-weight: theme('fontWeight.normal-dark');
      }

      img {
        @apply mr-1;
        width: 16px;
      }
    }

    .mat-mdc-option-pseudo-checkbox {
      @apply mr-2;
    }
  }

  .custom-mat-select-footer {
    @apply -mb-1;
    border-top: 1px solid var(--gray-200);
    padding-top: 1px;
    margin-top: 1px;

    .danger-option-item {
      .mdc-list-item__primary-text,
      .mat-mdc-menu-item-text {
        @apply text-typography-danger-primary;
      }
    }
  }
}

//checkboxes in selects
.custom-mat-select-panel {
  .mat-pseudo-checkbox-full {
    border-width: 1px;
    border-color: var(--gray-700);

    .dark & {
      border-color: var(--gray-700);
    }

    &.mat-pseudo-checkbox-checked {
      @apply bg-primary-700;

      .dark & {
        @apply bg-primary-400;
      }

      &::after {
        @apply text-white;
        width: 6px;
        height: 3px;
        border-bottom-width: 1px;
        border-left-width: 1px;
      }
    }

    &.mat-pseudo-checkbox-checked,
    &.mat-pseudo-checkbox-indeterminate {
      border-color: transparent;
    }
  }

  &.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
  &.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    @apply bg-primary-700;

    &::after {
      .dark & {
        @apply text-white;
        border-left-width: 1px;
        border-bottom-width: 1px;
      }
    }

    .dark & {
      @apply bg-primary-400;
    }
  }

  //override UX2 - remove and check later
  .mat-pseudo-checkbox-checked::after {
    left: 1px !important;
    transform: rotate(-45deg) scale(1.3) !important;
  }
}

.full-width-field .mat-mdc-form-field-infix {
  width: 100%;
}
