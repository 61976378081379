//https: //ionicframework.com/docs/api/modal#styling

ion-modal {
  &.modified-modal {
    @media only screen and (min-width: 800px) {
      --width: 700px;
    }

    @media only screen and (min-width: 1000px) {
      --width: 900px;
    }

    @media only screen and (min-width: 1200px) {
      --width: 1000px;
    }
  }

  &:has(.ion-page > .compact) {
    --height: auto;
  }
}
