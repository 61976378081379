@import 'variables';

.mat-mdc-slide-toggle {
  &.mat-primary {
    --mdc-switch-selected-track-color: var(--primary-700);
    --mdc-switch-selected-hover-track-color: var(--primary-700);
    --mdc-switch-selected-pressed-track-color: var(--primary-700);
    --mdc-switch-selected-focus-track-color: var(--primary-700);
    --mdc-switch-selected-handle-color: var(--white);
    --mdc-switch-selected-hover-handle-color: var(--white);
    --mdc-switch-selected-pressed-handle-color: var(--white);
    --mdc-switch-selected-focus-handle-color: var(--white);
    --mdc-switch-selected-focus-state-layer-color: var(--white);

    --mdc-switch-unselected-track-color: var(--gray-300);
    --mdc-switch-unselected-hover-track-color: var(--gray-400);
    --mdc-switch-unselected-pressed-track-color: var(--gray-400);
    --mdc-switch-unselected-focus-track-color: var(--gray-400);
    --mdc-switch-unselected-handle-color: var(--gray-500);
    --mdc-switch-unselected-hover-handle-color: var(--gray-600);
    --mdc-switch-unselected-pressed-handle-color: var(--gray-700);
    --mdc-switch-unselected-focus-handle-color: var(--gray-600);

    --mdc-switch-disabled-selected-track-color: var(--gray-400);
    --mdc-switch-disabled-selected-handle-color: var(--gray-200);
    --mdc-switch-disabled-unselected-track-color: var(--gray-400);
    --mdc-switch-disabled-unselected-handle-color: var(--gray-200);

    --mdc-switch-handle-surface-color: transparent;
    --mdc-switch-handle-elevation-shadow: none;

    .dark & {
      --mdc-switch-selected-track-color: var(--primary-400);
      --mdc-switch-selected-hover-track-color: var(--primary-400);
      --mdc-switch-selected-pressed-track-color: var(--primary-400);
      --mdc-switch-selected-focus-track-color: var(--primary-400);
    }
  }

  .mdc-switch {
    --mdc-switch-track-width: #{$switch-toggle-track-width};
    --mdc-switch-track-height: #{$switch-toggle-track-height};
    --mdc-switch-track-shape: #{$switch-toggle-track-border-radius};

    --mdc-switch-handle-width: #{$switch-toggle-handle-size};
    --mdc-switch-handle-height: #{$switch-toggle-handle-size};
    --mdc-switch-handle-shape: #{$switch-toggle-handle-border-radius};

    --mdc-switch-state-layer-size: #{$switch-toggle-handle-size};
    --mdc-switch-disabled-handle-opacity: #{$opacity-level-1};
    --mdc-switch-disabled-track-opacity: #{$opacity-level-2};

    &.mdc-switch--selected .mdc-switch__handle-track {
      transform: translateX(16px);
    }

    &.mdc-switch--selected:enabled,
    &.mdc-switch--unselected:enabled,
    &.mdc-switch--disabled {
      .mdc-switch__icons {
        display: none;
      }
    }

    .mdc-switch__handle {
      left: $switch-toggle-handle-offset;
    }
  }

  label {
    @apply text-body-m;
  }
}
