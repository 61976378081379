.mat-mdc-checkbox {
  .mdc-form-field {
    > label {
      @apply text-typography-secondary text-body-m pb-0 pl-2 font-normal;
      letter-spacing: -0.01em;

      .dark & {
        @apply font-normal-dark;
      }

      > * {
        @apply mr-1;

        &:last-child {
          @apply mr-0;
        }
      }
    }
  }

  &-checked {
    .mdc-form-field > label {
      @apply text-typography-primary;
    }
  }

  &-disabled {
    .mdc-form-field > label {
      @apply text-typography-disabled;
    }
  }

  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 18px;

    .mat-mdc-checkbox-touch-target {
      height: 100%;
      width: 100%;
    }

    .mdc-checkbox__checkmark {
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      --mdc-checkbox-selected-checkmark-color: var(--white);
    }

    .mdc-checkbox__background {
      @apply rounded-standard;
      border-width: 1px;
    }

    .mdc-checkbox__ripple {
      display: none;
    }
  }
}

.mdc-checkbox__background {
  --mdc-checkbox-unselected-icon-color: var(--gray-400);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-800);
  --mdc-checkbox-unselected-focus-icon-color: var(--gray-500);
  --mdc-checkbox-disabled-unselected-icon-color: var(--gray-300);
  --mdc-checkbox-selected-icon-color: var(--primary-700);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-700);
  --mdc-checkbox-unselected-hover-icon-color: var(--gray-500);

  .dark & {
    --mdc-checkbox-unselected-hover-icon-color: var(--gray-500);
    --mdc-checkbox-selected-icon-color: var(--primary-400);
    --mdc-checkbox-selected-focus-icon-color: var(--primary-400);
    --mdc-checkbox-selected-hover-icon-color: var(--primary-500);
  }

  .ng-touched.ng-invalid .mat-mdc-checkbox[required] & {
    --mdc-checkbox-unselected-icon-color: var(--red-600);
  }
}

.checkbox-group-col {
  display: flex;
  flex-direction: column;

  > .mat-mdc-checkbox {
    @apply mb-1;

    &:last-child {
      @apply mb-0;
    }
  }
}

.checkbox-group-row {
  > .mat-mdc-checkbox {
    @apply mr-4;

    &:last-child {
      @apply mr-0;
    }
  }
}
