$cmdk-width: 800px;

.custom-cmdk-dialog {
  @apply bg-background-secondary rounded-medium;
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.55);
  border: 1px solid var(--gray-200);
  width: $cmdk-width;
  max-width: $cmdk-width;
  max-height: 90vh;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(calc($cmdk-width / -2));

  @media screen and (max-width: 800px) {
    @apply w-full;
    max-width: initial;
    left: 0;
    transform: translateX(0);
  }
}

.custom-cmdk-dialog-header {
  @apply p-4;
  border-bottom: 1px solid var(--gray-200);
}

.custom-cmdk-dialog-input {
  @apply gap-2;
  display: flex;
  align-items: center;

  input {
    @apply bg-background-secondary text-typography-primary text-body-l py-1;

    &::placeholder {
      @apply text-typography-disabled text-body-l;
    }

    &:focus {
      outline: none;
    }
  }
}

.custom-cmdk-dialog-footer {
  @apply p-4;
  border-top: 1px solid var(--gray-200);
  display: flex;
  align-items: center;
}
