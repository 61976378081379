@import 'mixins';
@import 'variables';

html {
  scroll-behavior: smooth;
}

body {
  @apply font-primary text-typography-primary bg-background-primary text-body-m font-normal;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;

  &.dark {
    @apply font-normal-dark;
  }

  .exam {
    @apply font-normal-exam;
  }
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

::before,
::after {
  border-color: unset;
}

code {
  @apply text-typography-secondary;
}

// scrollbar starts
::-webkit-scrollbar {
  width: $scrollbar-width;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-200;
  border: 1px solid var(--gray-200);
  border-radius: 0;

  &:hover,
  &:active {
    @apply bg-gray-300;
  }
}

::-webkit-scrollbar-track {
  @apply bg-gray-100;
  border: none;
  border-radius: 0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// scrollbar ends

p {
  margin-top: 0;
  margin-bottom: 0.3rem;
}

a {
  text-decoration: none;
  transition: opacity 0.25s ease-in;
  cursor: pointer;

  &:not(.mdc-button, .mat-menu-link, .dashed-link, .primary-link, .accent-link, .custom-mat-menu-item):hover {
    opacity: 0.85;
    text-decoration: none;
  }

  &.mat-menu-link:hover {
    color: inherit;
    text-decoration: none;
  }

  &:focus-visible:not(.mdc-button, .mat-menu-link, .dashed-link, .primary-link, .accent-link, .custom-mat-menu-item) {
    opacity: 0.85;
    text-decoration: underline;
    outline: none;
  }

  &.dashed-link,
  &.dashed-link:focus,
  &.dashed-link:visited {
    color: theme('colors.typography.primary');
    border-bottom: 1px dashed currentColor;
  }

  &.dashed-link:hover {
    color: theme('colors.typography.primary');
    border-style: solid;
  }

  &.dashed-link[disabled] {
    color: theme('colors.typography.secondary');
  }

  // if changed here, change it accordingly in .button-primary-link and .button-accent-link in _mat-button.scss
  &.primary-link {
    color: theme('colors.primary.700');

    &:hover {
      color: theme('colors.primary.800');
    }
  }

  &.accent-link {
    color: theme('colors.yellow.800');

    &:hover {
      color: theme('colors.yellow.900');
    }
  }

  &.primary-link,
  &.accent-link {
    font-weight: theme('fontWeight.medium');
    transition: color 0.25s ease-in;

    dojo-icon {
      vertical-align: -2px;
    }

    .dark & {
      font-weight: theme('fontWeight.medium-dark');
    }
  }
}

//To fix bootstrap lack
[hidden] {
  display: none !important;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

//UX3
* {
  &:focus-visible {
    outline: 1px solid var(--focus-color);
  }
}

router-outlet {
  display: none;
}

.center-absolute {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

img.avatar-content {
  object-fit: cover;
}

//UX3 ngx-avatar
.avatar-container {
  @include flex-center;
  height: 100%;

  .avatar-content {
    width: 100%;
    height: 100%;
  }
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

.cdk-drag-preview {
  box-sizing: border-box;
  opacity: 1;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
}

/* Elements with disabled attribute will have pointer events disabled */
[disabled] {
  pointer-events: none;
}

/* This prevents it from being disabled when it has the disabled attribute with a value of false. */
[disabled='false'] {
  pointer-events: initial;
}

/* Set up additional styling to ensure consistenty across browsers */

.divider {
  border: 1px dashed var(--gray-300);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bg-squared-pattern {
  background-image: linear-gradient(var(--canvas-grid-color) 1px, transparent 1px),
    linear-gradient(90deg, var(--canvas-grid-color) 1px, transparent 1px);
  background-size:
    100px 100px,
    100px 100px,
    20px 20px,
    20px 20px;
  background-position:
    -2px -2px,
    -2px -2px,
    -1px -1px,
    -1px -1px;
}

.gojs-diagram-buttons {
  position: absolute;
  bottom: 18px;
  right: 200px;
  z-index: 100;
}

.mat-badge-before .mat-badge-content {
  left: 2px;
  top: -4px;
}

.gojs-sidebar {
  @apply bg-background-primary;
  min-height: 50px;
  width: 50px;
  border-radius: 3px;
  -webkit-box-shadow:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.01);
  box-shadow:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.01);
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}

.loading-indicator-overlay {
  width: 100vw;
  height: 100vh;
  max-width: none;
  background-color: rgba(255, 255, 255, 0.7);

  .dark & {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .mat-mdc-dialog-container {
    border-radius: 0;
    background-color: var(--gray-100);
  }
}

.job-import {
  @apply mr-1;
  position: relative;
  width: 14px;
  height: 14px;

  svg {
    @apply h-full w-full;
    position: absolute;

    .fa-secondary {
      opacity: 1;
    }
  }

  &.full svg path,
  &.full_import svg path {
    fill: var(--gray-800);

    .dark .mat-selected & {
      fill: var(--gray-400);
    }
  }

  &.incremental svg path,
  &.incremental_import svg path {
    &:first-of-type {
      fill: var(--gray-500);

      .dark .mat-selected & {
        fill: var(--gray-900);
      }
    }

    &:last-of-type {
      fill: var(--gray-800);

      .dark .mat-selected & {
        fill: var(--gray-400);
      }
    }
  }
}

.gsfBackground {
  border-color: #fcf8aa;
  opacity: 58%;
  margin-bottom: 14px;
}

//UX3
.previewable-img {
  position: relative;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.25s linear;

  &:hover {
    opacity: 1;

    &::after {
      opacity: 0.85;
    }
  }

  &::after {
    @apply rounded-standard bottom-2 right-2 bg-gray-200 p-1;
    display: block;
    content: '\e092';
    position: absolute;
    width: 24px;
    height: 24px;
    font-family: 'Font Awesome 6 Pro';
    font-size: 17px;
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.25s linear;
  }
}

.loader-centered {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-transparent {
  background-color: transparent;
}

//Health severities
.health {
  display: inline-flex;
  border-radius: 50%;
  width: 9px;
  height: 9px;

  &.small-indicator {
    width: 6px;
    height: 6px;
  }

  &.healthy {
    background-color: var(--green-500);
  }

  &.at-risk {
    background-color: var(--yellow-500);
  }

  &.unhealthy {
    background-color: var(--red-500);
  }

  &.info {
    background-color: var(--gray-500);
  }
}

//github icon fix in dark mode
.dark .github svg {
  filter: invert(1);
}

.code-mono-inline {
  @include code-mono-inline($is-gray-bg: true);
}

//introduced it as tailwind doesn't have this class
.scrollbar-gutter-stable {
  scrollbar-gutter: stable;
}
