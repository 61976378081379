@import 'variables';
@import 'mixins';

@mixin button-focused($border-color: var(--focus-color)) {
  &:focus-visible {
    border-color: $border-color;

    .dark & {
      border-color: $border-color;
    }
  }
}

@mixin button-colors-set(
  $background,
  $ripple,
  $border,
  $background-dark: $background,
  $ripple-dark: $ripple,
  $border-dark: $border,
  $text: var(--gray-1200),
  $text-dark: var(--typography-primary),
  $text-disabled: var(--typography-disabled),
  $text-disabled-dark: var(--typography-disabled)
) {
  @include button-focused;

  --mdc-text-button-label-text-color: var(--typography-primary);
  --mdc-filled-button-container-color: #{$background};
  --mat-text-button-state-layer-color: #{$ripple};
  --mat-text-button-ripple-color: rgba(#{$ripple}, 0.1);
  --mat-filled-button-state-layer-color: #{$ripple};
  --mat-filled-button-ripple-color: rgba(#{$ripple}, 0.1);
  --mdc-filled-button-label-text-color: #{$text};
  --mdc-filled-button-disabled-label-text-color: #{$text-disabled};
  --mdc-filled-button-disabled-container-color: #{$background};
  border-color: #{$border};

  .dark & {
    --mdc-text-button-label-text-color: var(--typography-primary);
    --mdc-filled-button-container-color: #{$background-dark};
    --mat-text-button-state-layer-color: #{$ripple-dark};
    --mat-text-button-ripple-color: rgba(#{$ripple-dark}, 0.1);
    --mat-filled-button-state-layer-color: #{$ripple-dark};
    --mat-filled-button-ripple-color: rgba(#{$ripple-dark}, 0.1);
    --mdc-filled-button-label-text-color: #{$text-dark};
    --mdc-filled-button-disabled-label-text-color: #{$text-disabled-dark};
    --mdc-filled-button-disabled-container-color: #{$background-dark};
    border-color: #{$border-dark};
  }
}

@mixin icon-button-colors-set(
  $icon-color: var(--typography-secondary),
  $ripple-color: var(--gray-200),
  $dark-icon-color: $icon-color
) {
  color: #{$icon-color};
  --mdc-icon-button-icon-color: #{$icon-color};
  --mat-icon-button-ripple-color: rgba(#{$ripple-color}, 0.1);
  --mat-icon-button-state-layer-color: #{$ripple-color};

  .dark & {
    color: #{$dark-icon-color};
    --mdc-icon-button-icon-color: #{$dark-icon-color};
    --mat-icon-button-ripple-color: rgba(#{$ripple-color}, 0.1);
    --mat-icon-button-state-layer-color: #{$ripple-color};
  }
}

@mixin button-list-item-helper($color) {
  @apply bg-#{$color};
  --mat-text-button-ripple-color: var(--#{$color});
  --mat-text-button-state-layer-color: var(--#{$color});
  border-color: var(--#{$color});
}

@mixin button-list-item($lighter: false) {
  $active-color: if($lighter, gray-300, gray-200);
  $focus-color: if($lighter, primary-200, primary-100);
  $hover-color: if($lighter, gray-200, gray-100);

  &.active {
    @include button-list-item-helper($active-color);
  }

  &:not(:focus):not(.active):hover {
    @include button-list-item-helper($hover-color);
  }

  &.active:focus {
    @include button-list-item-helper($focus-color);
  }
}

//Base button
.mdc-button.mat-mdc-button-base {
  &.mat-unthemed,
  &.mat-warn {
    @include button-colors-set($background: unset, $ripple: var(--gray-100), $border: transparent);

    &:hover {
      border-color: var(--gray-100);
    }
  }

  &.help-widget-button {
    @include button-colors-set(
      $background: var(--gray-100),
      $ripple: var(--gray-200),
      $border: var(--gray-200),
      $background-dark: var(--gray-200),
      $ripple-dark: var(--gray-300),
      $border-dark: var(--gray-300)
    );
  }

  &.button-list-item {
    @include button-list-item();

    &:focus {
      @apply border-primary-500;
    }

    &.lighter-background {
      @include button-list-item(true);
    }
  }

  &[mat-flat-button] {
    &.mat-unthemed {
      @include button-colors-set($background: var(--gray-100), $ripple: var(--gray-200), $border: var(--gray-300));

      &:disabled {
        border-color: var(--gray-200);
      }

      &.selected {
        border-color: var(--primary-600);
      }

      &.custom-tab-button {
        @apply rounded-large text-typography-secondary;
        @include button-colors-set($background: var(--gray-100), $ripple: var(--gray-200), $border: transparent);

        &.is-active {
          @apply text-typography-primary;
          @include button-colors-set(
            $background: var(--primary-100),
            $ripple: var(--primary-200),
            $border: var(--primary-600)
          );
        }
      }

      &.filter-button {
        @apply text-typography-secondary;
        @include button-padding-set;
        @include button-colors-set($background: var(--gray-200), $ripple: var(--gray-200), $border: transparent);

        &.is-active {
          @apply text-pink-900;
          @include button-colors-set($background: var(--pink-100), $ripple: var(--pink-100), $border: var(--pink-200));

          svg,
          path {
            fill: var(--pink-900);
          }
        }

        &:not(.is-active):hover {
          @apply bg-gray-200;
        }
      }

      .job-triggered-button & {
        @include button-colors-set($background: var(--gray-200), $ripple: var(--gray-300), $border: transparent);
      }

      &.transparent-primary-button {
        @include button-colors-set($background: transparent, $ripple: var(--gray-200), $border: var(--gray-300));

        &-no-border {
          @extend .transparent-primary-button;

          &:not(:focus-visible) {
            border-color: transparent;
          }
        }
      }
    }

    &.mat-primary {
      @include button-colors-set(
        $background: var(--primary-700),
        $ripple: var(--primary-800),
        $border: var(--primary-700),
        $background-dark: var(--primary-400),
        $ripple-dark: var(--primary-500),
        $border-dark: var(--primary-400),
        $text: var(--white),
        $text-disabled: rgba(255, 255, 255, $opacity-level-3)
      );
    }

    &.mat-accent {
      @include button-colors-set(
        $background: var(--yellow-400),
        $ripple: var(--yellow-500),
        $border: var(--yellow-400),
        $background-dark: var(--yellow-800),
        $ripple-dark: var(--yellow-900),
        $border-dark: var(--yellow-800),
        $text: var(--white),
        $text-disabled: rgba(255, 255, 255, $opacity-level-3)
      );
    }

    &.mat-warn {
      @include button-colors-set(
        $background: var(--red-800),
        $ripple: var(--red-900),
        $border: var(--red-800),
        $background-dark: var(--red-400),
        $ripple-dark: var(--red-500),
        $border-dark: var(--red-400),
        $text: var(--white),
        $text-disabled: rgba(255, 255, 255, $opacity-level-3)
      );
    }

    &.mat-success {
      @include button-colors-set(
        $background: var(--green-700),
        $ripple: var(--green-800),
        $border: var(--green-700),
        $background-dark: var(--green-600),
        $ripple-dark: var(--green-700),
        $border-dark: var(--green-600),
        $text: var(--white),
        $text-disabled: rgba(255, 255, 255, $opacity-level-3)
      );
    }

    //Dropdown button
    &.custom-button-dropdown {
      .chevron-icon::before {
        @apply bg-gray-300;
      }

      &.mat-primary {
        .chevron-icon::before {
          @apply bg-primary-800;

          .dark & {
            @apply bg-primary-500;
          }
        }

        &:hover {
          .chevron-icon::before {
            @apply bg-primary-900;

            .dark & {
              @apply bg-primary-600;
            }
          }
        }
      }
    }
  }
}

//Icon button
.mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
  @include button-focused;

  &:hover {
    border-color: var(--gray-200);
  }

  &.mat-unthemed {
    @include icon-button-colors-set();
  }

  &.mat-primary {
    @include icon-button-colors-set($icon-color: var(--primary-700), $dark-icon-color: var(--primary-600));
  }

  &.mat-accent {
    @include icon-button-colors-set($icon-color: var(--yellow-400), $dark-icon-color: var(--yellow-900));
  }

  &.mat-warn {
    @include icon-button-colors-set($icon-color: var(--red-600), $dark-icon-color: var(--red-700));
  }

  &.add-icon-button {
    @apply bg-gray-200;
    @include icon-button-colors-set($ripple-color: var(--gray-300));
  }

  &[disabled] {
    @apply text-typography-disabled;
  }

  &.bordered {
    border: 1px solid var(--gray-300);

    :hover {
      @apply border-gray-300;
    }
  }

  .dark & {
    &.icon-button-close,
    &.icon-button-fullscreen {
      @apply text-typography-primary;
    }

    &[disabled] {
      @apply text-typography-disabled;
    }
  }
}
