@import 'variables';

$mat-menu-outer-offset: 1px;
$border-menu-width: 1px;
$menu-header-space-height: 54px;
$menu-header-org-space-height: 21px;
$menu-footer-height: 34px;
$menu-header-branches-height: 32px;

.mat-mdc-menu-panel {
  --mdc-theme-surface: var(--background-secondary);
  --mdc-theme-on-surface: var(--typography-secondary);
  --mdc-shape-medium: theme('borderRadius.standard');
  --mdc-typography-body1-font-weight: theme('fontWeight.medium');
  border: #{$border-menu-width} solid var(--gray-300);

  .dark & {
    --mdc-typography-body1-font-weight: theme('fontWeight.medium-dark');
  }

  &.mat-mdc-menu-panel {
    max-width: 260px;
    min-width: 165px;
    max-height: 70vh;

    &.no-width-constraints {
      max-width: unset;
    }

    .branch-selector-menu {
      width: 285px;
      display: flex;
      flex-direction: column;
    }
  }

  &.custom-menu-wrap.mat-mdc-menu-panel {
    overflow: hidden;

    .mat-mdc-menu-content {
      height: 100%;
    }
  }

  &.has-padding .mat-mdc-menu-content {
    @apply p-2;
  }

  .mat-mdc-menu-item {
    min-height: auto;
    --mdc-list-list-item-hover-label-text-color: var(--typography-primary);
    --mdc-list-list-item-focus-label-text-color: var(--typography-primary);
    --mat-menu-item-label-text-color: var(--typography-primary);

    .mat-mdc-menu-item-text {
      --mat-menu-item-label-text-weight: theme('fontWeight.normal');

      .dark & {
        --mat-menu-item-label-text-weight: theme('fontWeight.normal-dark');
      }
    }

    &.mat-mdc-menu-item-submenu-trigger {
      @apply pr-3;
    }

    &[disabled] {
      opacity: 1;

      .mat-mdc-menu-item-text {
        @apply text-typography-disabled;
      }
    }

    &:not([disabled]) {
      &.cdk-program-focused,
      &.cdk-keyboard-focused,
      &.mat-mdc-menu-item-highlighted {
        @apply bg-gray-200;
      }
    }

    &:visited,
    &:link {
      @apply text-typography-primary;
    }
  }

  .mat-mdc-menu-content {
    @apply py-0;

    > * {
      height: 100%;
    }
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item-text {
    --mat-menu-item-label-text-size: theme('fontSize.body-m.0');
    --mat-menu-item-label-text-line-height: theme('fontSize.body-m.1');
  }

  .section-title.mat-mdc-menu-item {
    &:hover {
      @apply bg-gray-200;
      cursor: default;
    }

    .mat-mdc-menu-item-text {
      @apply text-typography-secondary text-body-s;
    }
  }

  .mat-mdc-menu-item.mat-menu-item-readonly:not(:disabled) {
    &:hover {
      @apply bg-background-secondary;
      cursor: default;
    }
  }

  .menu-footer,
  .menu-header {
    @apply bg-background-secondary;
  }

  .menu-header-search {
    @apply dark:shadow-scrollable shadow-scrollable-light;
  }

  .custom-menu-inner {
    height: 100%;
  }

  .custom-menu-inner-content {
    height: calc(100% - #{$menu-footer-height});
    display: flex;
    flex-direction: column;
  }

  .custom-menu-items {
    overflow-y: auto;

    &.branches-dropdown-items:not(:empty) {
      height: calc(100% - #{$menu-header-branches-height});
      max-height: 300px;
    }
  }

  .menu-footer {
    padding-top: $mat-menu-outer-offset;
    border-top: 1px solid var(--gray-300);
  }

  .mat-mdc-menu-submenu-icon {
    display: none;
  }

  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    width: 100%;

    .menu-item-label {
      &:not(:last-child) {
        @apply mr-1;
      }

      &:not(:first-child) {
        @apply ml-1;
      }
    }
  }

  .mat-menu-icons-right {
    margin-left: auto;
  }

  .empty-text {
    @apply text-typography-secondary px-3 py-2;

    &:hover {
      cursor: default;
    }
  }

  .custom-mat-form-wrapper {
    width: 100%;

    &.filterable-list {
      .mat-mdc-form-field .mdc-text-field {
        @apply px-2;
        border: none;
      }
    }

    .mat-mdc-form-field {
      width: 100%;
    }
  }

  .dialog-branch-filter {
    .custom-mat-form-wrapper .mat-mdc-form-field .mdc-text-field {
      border: 1px solid var(--primary-600);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

//Common styling for mtx-popover(custom-mat-menu) and mat-menu
.mat-mdc-menu-panel,
.custom-mat-menu {
  .mat-mdc-menu-item,
  .custom-mat-menu-item {
    @apply px-3 py-2;

    + .mat-mdc-menu-item,
    + .menu-footer,
    + .custom-mat-menu-item,
    + .section-title {
      margin-top: $mat-menu-outer-offset;
    }

    &.danger-button span,
    &.danger-button {
      @apply text-typography-danger-primary;
    }

    &:hover:not([disabled]),
    &.mat-mdc-menu-item-highlighted {
      @apply bg-gray-200;
    }
  }

  .menu-item-wrapper.has-border-top,
  .mat-mdc-menu-item.has-border-top {
    position: relative;

    &::before {
      @apply bg-gray-300;
      position: absolute;
      height: $border-menu-width;
      top: calc(-1 * (#{$mat-menu-outer-offset} + #{$border-menu-width}));
      width: 100%;
      content: '';
    }
  }

  .menu-item-wrapper,
  .mat-mdc-menu-item {
    + .mat-mdc-menu-item,
    + .menu-item-wrapper {
      margin-top: $mat-menu-outer-offset;

      &.has-border-top {
        margin-top: calc(#{$mat-menu-outer-offset * 2} + #{$border-menu-width});
      }
    }
  }

  .mat-mdc-menu-item.has-border-top {
    overflow: inherit;
  }

  .section-title {
    @apply bg-gray-200 px-3 py-1;
    position: sticky;
    top: 0;
    z-index: 1;

    + .custom-mat-menu-item,
    + .mat-mdc-radio-group,
    + .mat-mdc-menu-item {
      margin-top: $mat-menu-outer-offset;
    }
  }

  .menu-icon,
  .custom-mat-menu-icon {
    padding: 1px;
    width: $menu-icon-wrapper-size;
    height: $menu-icon-wrapper-size;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > fa-icon {
      display: inline-flex;
    }
  }
}
