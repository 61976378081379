@import 'mixins';

.mat-mdc-tooltip,
.mtx-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: theme('fontFamily.primary');
  --mdc-plain-tooltip-supporting-text-size: theme('fontSize.body-s.0');
  --mdc-plain-tooltip-supporting-text-line-height: theme('fontSize.body-s.1');
  --mdc-plain-tooltip-container-color: var(--gray-100);
  --mdc-plain-tooltip-supporting-text-color: var(--typography-primary);
  --mdc-plain-tooltip-supporting-text-weight: theme('fontWeight.normal');

  .dark & {
    --mdc-plain-tooltip-supporting-text-weight: theme('fontWeight.normal-dark');
  }

  .dark & {
    --mdc-plain-tooltip-container-color: var(--gray-100);
    --mdc-plain-tooltip-supporting-text-color: var(--typography-primary);
  }

  .mdc-tooltip__surface {
    @apply border-standard px-2 py-1;
    @include border;
  }
}

.mtx-mdc-tooltip {
  .mdc-tooltip__surface {
    min-width: 0;
    text-align: left;
  }
}
