//UX2 and UX3
as-split {
  > .as-split-gutter {
    //TODO Lyuba find the way to overwrite without important
    background-color: transparent !important;
    position: relative;

    &::before {
      @apply bg-gray-200;
      position: absolute;
      content: '';
      z-index: 2;
    }

    &:hover {
      @apply bg-gray-200 #{'!important'};
    }

    .as-split-gutter-icon {
      background: none !important;
      position: absolute;
      z-index: 2;
    }
  }

  &.as-horizontal {
    > .as-split-gutter {
      .as-split-gutter-icon {
        @apply px-1;
      }

      &::before {
        width: 1px;
        height: 100%;
      }

      &:hover::before {
        @apply px-0.5;
      }
    }
  }

  &.as-vertical {
    > .as-split-gutter {
      .as-split-gutter-icon {
        @apply py-1;
      }

      &::before {
        width: 100%;
        height: 1px;
      }

      &:hover::before {
        @apply py-0.5;
      }
    }
  }
}

.monaco-sash {
  &:hover {
    @apply bg-gray-200;
  }
}
