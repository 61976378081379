@import 'mixins';
@import 'variables';

.mtx-popover-panel {
  @include border;
  max-width: 510px;

  &.custom-mtx-popover {
    @apply bg-background-primary rounded-standard p-4;

    .mtx-popover-content {
      .popover-section-title,
      .popover-text-normal {
        @apply text-typography-secondary text-body-s mb-0;
      }

      .popover-text-highlight {
        @apply text-typography-primary font-secondary text-code-s mb-0;
      }
    }
  }

  &.custom-mat-menu {
    @apply bg-background-secondary rounded-standard text-body-m p-0;
    @include border(var(--gray-300));

    .mtx-popover-content {
      display: flex;
      flex-direction: column;
    }

    .section-title {
      @apply text-body-s text-typography-secondary;
    }

    .custom-mat-menu-item {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      width: 100%;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        @apply text-typography-disabled;
        cursor: default;
      }
    }

    .custom-mat-menu-icon {
      + * {
        @apply ml-1;
      }
    }
  }

  &.custom-mat-tooltip {
    @apply rounded-standard text-body-s text-typography-primary bg-gray-100 px-2 py-1;
    @include border;
    max-width: 200px;
    box-shadow: none;

    .custom-mat-tooltip-text-secondary {
      @apply text-typography-primary;
    }
  }

  &.custom-mtx-popover,
  &.custom-mat-menu,
  &.custom-mat-tooltip {
    .mtx-popover-direction-arrow {
      display: none;
    }
  }
}
