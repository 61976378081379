@use 'sass:map';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use 'angular-material/palette' as palette;

@use '@ng-matero/extensions' as mtx;

@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define typographies
$typography-level: mat.define-typography-level(
  $letter-spacing: normal,
  $font-size: theme('fontSize.body-m.0'),
  $line-height: theme('fontSize.body-m.1'),
  $font-weight: 400,
);

$button-level: mat.define-typography-level(
  $letter-spacing: normal,
  $font-size: theme('fontSize.body-m.0'),
  $line-height: theme('fontSize.body-m.1'),
);

$typography: mat.define-typography-config(
  $font-family: theme('fontFamily.primary'),
  $headline-1: $typography-level,
  $headline-5: $typography-level,
  $headline-6: $typography-level,
  $subtitle-1: $typography-level,
  $body-1: $typography-level,
  $button: $button-level,
);

// Define a light theme
$light-primary: mat.define-palette(palette.$primary-pat, 700);
$light-accent: mat.define-palette(palette.$accent-pat, 700);
$light-warn: mat.define-palette(palette.$warn-pat, 700);
$light-theme-base: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
    typography: $typography,
  )
);

$light-theme: map.deep-merge($light-theme-base, palette.$material-theme-overrides);

// Define a dark theme
$dark-primary: mat.define-palette(palette.$primary-pat, 700);
$dark-accent: mat.define-palette(palette.$accent-pat, 700);
$dark-warn: mat.define-palette(palette.$warn-pat, 700);
$dark-theme-base: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
    typography: $typography,
  )
);

$dark-theme: map.deep-merge($dark-theme-base, palette.$material-theme-overrides);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($light-theme);
@include mtx.all-component-themes($light-theme);

.dark {
  @include mat.all-component-colors($dark-theme);
  @include mtx.all-component-colors($dark-theme);
}

// Reset default material indicators styles
@include mat.strong-focus-indicators(
  (
    border-width: 0,
    border-radius: 0,
  )
);

@import '@fortawesome/fontawesome-pro/css/all.css';
@import 'root-variables-colors';
