$default-size: 16px;

.mat-mdc-radio-button {
  .mdc-radio {
    --mdc-radio-state-layer-size: #{$default-size};
  }

  .mdc-form-field {
    label {
      @apply text-typography-secondary text-body-m pb-0 pl-2 font-normal;
      display: flex;
      align-items: center;

      .dark & {
        @apply font-normal-dark;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.mat-mdc-radio-checked label {
    @apply text-typography-primary;
  }

  &.mat-primary .mdc-radio--disabled + label {
    @apply text-typography-disabled;

    .dark & {
      @apply text-typography-disabled;
    }
  }

  &.mat-primary .mdc-radio {
    height: $default-size;
    width: $default-size;

    .mat-mdc-radio-touch-target,
    .mdc-radio__background {
      height: 100%;
      width: 100%;
    }

    .mdc-radio__background {
      &::before {
        display: none;
      }

      .mdc-radio__outer-circle {
        border-width: 1px;
        --mdc-radio-unselected-icon-color: var(--gray-400);
        --mdc-radio-selected-icon-color: var(--gray-500);
        --mdc-radio-selected-hover-icon-color: var(--gray-600);
        --mdc-radio-unselected-hover-icon-color: var(--gray-500);
        --mdc-radio-unselected-pressed-icon-color: var(--gray-500);
        --mdc-radio-unselected-focus-icon-color: var(--gray-500);
        --mdc-radio-disabled-unselected-icon-color: var(--gray-200);
        --mdc-radio-disabled-unselected-icon-opacity: 1;
      }

      .mdc-radio__inner-circle {
        border-width: 8px;
        --mdc-radio-selected-icon-color: var(--primary-700);

        .dark & {
          --mdc-radio-selected-icon-color: var(--primary-600);
        }
      }
    }
  }
}

.mat-mdc-form-field-error {
  .mdc-radio__outer-circle {
    --mdc-radio-unselected-icon-color: theme('colors.red.600') !important;
  }
}

.radio-group-col {
  display: flex;
  flex-direction: column;

  > .mat-mdc-radio-button:not(.custom-mat-menu-item) {
    @apply mb-1;

    &:last-child {
      @apply mb-0;
    }
  }
}
