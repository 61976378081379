$step-icon-size: theme('spacing.4');

.custom-mat-accordion.mat-accordion {
  @apply gap-2;
}

.custom-mat-accordion.mat-accordion,
.custom-mat-accordion-error.mat-accordion,
.custom-accordion-stepper.mat-accordion {
  display: flex;
  flex-direction: column;

  .mat-expansion-panel {
    @apply rounded-standard;
    box-shadow: none;

    &:first-of-type,
    &:last-of-type {
      --mat-expansion-container-shape: theme('borderRadius.standard');
    }

    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing) {
      @apply rounded-standard;
    }
  }

  .mat-expansion-panel-header {
    @apply px-4 py-2;
    --mat-expansion-header-collapsed-state-height: auto;
    --mat-expansion-header-expanded-state-height: auto;
    --mat-expansion-header-text-size: theme('fontSize.body-m.0');
    --mat-expansion-header-text-weight: theme('fontWeight.normal');
    --mat-expansion-header-text-line-height: theme('fontSize.body-m.1');
    --mat-expansion-header-text-tracking: normal;

    .dark & {
      --mat-expansion-header-text-weight: theme('fontWeight.normal-dark');
    }

    &[aria-disabled='true'] {
      cursor: default;
    }

    .mat-content {
      align-items: center;

      &.mat-content-hide-toggle {
        @apply m-0;
      }
    }

    &,
    .mat-content {
      &:has(.mat-expansion-indicator) .step-title {
        @apply pl-6;
      }
    }
  }

  .mat-expansion-panel-body,
  .mat-expansion-panel-content {
    --mat-expansion-container-text-weight: theme('fontWeight.normal');

    .dark & {
      --mat-expansion-container-text-weight: theme('fontWeight.normal-dark');
    }
  }

  .mat-expansion-panel-header-title {
    @apply text-typography-tertiary;
    overflow-x: hidden;
  }

  .custom-expansion-panel-icon {
    @apply mr-2;
  }

  .mat-expanded {
    > .custom-expansion-panel-icon {
      transform: rotate(180deg);
    }

    > .mat-expansion-indicator {
      top: 2px;

      &::after {
        --mat-expansion-header-indicator-color: var(--typography-primary);
      }
    }
  }

  .mat-expansion-panel-spacing {
    @apply m-0;
  }

  .mat-expansion-indicator {
    @apply ml-4;
    position: relative;
    top: -2px;

    &::after {
      --mat-expansion-header-indicator-color: var(--typography-secondary);
      border-width: 0 1px 1px 0;
      padding: 5px;
      vertical-align: inherit;
    }
  }
}

.custom-mat-accordion-error.mat-accordion {
  .mat-expansion-panel-header {
    --mat-expansion-header-hover-state-layer-color: transparent;
    @apply px-0 py-2;

    &.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
      transform: rotate(-90deg) !important;
      top: 0;

      &::after {
        @apply p-1;
      }
    }

    &.mat-expanded {
      &.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
        transform: rotate(0deg) !important;
        top: -2px;
        left: 2px;
      }
    }
  }

  .mat-expansion-panel-body {
    @apply px-0 pb-2;
    letter-spacing: normal;
  }
}

.custom-mat-accordion.mat-accordion {
  .mat-expansion-panel {
    @apply bg-background-tertiary;
  }

  .mat-expansion-panel-body {
    @apply bg-background-tertiary p-4;
    border-top: 1px solid var(--gray-300);
  }
}

//Accordion stepper
.custom-accordion-stepper.mat-accordion {
  .mat-expansion-panel {
    position: relative;

    &::before {
      @apply bg-gray-300;
      position: absolute;
      z-index: 1;
      top: calc(#{$step-icon-size} + theme('spacing.4'));
      bottom: 0;
      left: calc(#{$step-icon-size} / 2);
      width: 1px;
      content: '';
      transform: translateX(-50%);
    }

    &.disabled {
      .mat-expansion-panel-header {
        @apply mb-0;

        &:hover {
          .step-title {
            background: none;
          }
        }
      }
    }
  }

  .mat-expansion-panel-header {
    --mat-expansion-header-hover-state-layer-color: transparent;
    --mat-expansion-header-focus-state-layer-color: transparent;
    @apply mb-2 p-0;

    &:not([aria-disabled='true']):focus-visible {
      &.cdk-focused,
      &.cdk-keyboard-focused {
        background: none;

        &::before {
          background: none;
        }

        .step-title {
          outline: 1px solid var(--focus-color);
          outline-offset: -1px;
        }
      }
    }

    &:hover {
      .step-title {
        @apply bg-background-tertiary;
      }
    }

    .step-status-icon {
      @apply mr-2;
      display: flex;

      ::ng-deep svg {
        width: $step-icon-size;
        height: $step-icon-size;
      }
    }

    .step-title {
      @apply rounded-standard dark:font-medium-dark text-typography-primary gap-1 py-2 pl-2 pr-3 font-medium;
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    &.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
      @apply mt-2;
      left: 30px;
      position: absolute;
      top: 0;
      transform: rotate(-90deg) !important;

      &::after {
        @apply p-1;
      }
    }

    &.mat-expanded {
      &.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
        @apply mt-1;
        left: 32px;
        top: 2px;
        transform: rotate(0deg) !important;
      }
    }
  }

  .mat-expansion-panel-header-title {
    @apply mr-0;
  }

  > .mat-expansion-panel-body {
    @apply pb-6 pl-7;
  }

  .mat-expansion-panel-body {
    @apply pb-5 pr-0;
    letter-spacing: normal;

    .step-content-background {
      @apply rounded-standard bg-gray-100;

      .dark & {
        @apply bg-gray-200;
      }
    }
  }
}
