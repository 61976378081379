.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primary-700);

  .dark & {
    --mdc-linear-progress-active-indicator-color: var(--primary-700);
  }

  .mdc-linear-progress__buffer-bar {
    @apply bg-primary-100;

    .dark & {
      @apply bg-primary-100;
    }
  }
}

//Spinner
.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--primary-700);
}

.secondary-spinner.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--typography-primary);
}

.tertiary-spinner.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--primary-700);

  .dark & {
    --mdc-circular-progress-active-indicator-color: var(--typography-primary);
  }
}

.quaternary-spinner.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--typography-quaternary);
}
