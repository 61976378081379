.custom-mat-bottom-sheet {
  display: flex;
  flex-direction: column;

  .mat-bottom-sheet-container {
    --mat-bottom-sheet-container-background-color: var(--background-primary);
    @apply p-4;
    display: flex;
    flex-direction: column;
    min-width: 50vw;

    .dark & {
      --mat-bottom-sheet-container-background-color: var(--background-secondary);
    }

    &.mat-bottom-sheet-container-xlarge,
    &.mat-bottom-sheet-container-large,
    &.mat-bottom-sheet-container-medium {
      --mat-bottom-sheet-container-shape: theme('borderRadius.standard');
    }
  }
}
